import React from "react";
import { cn } from "@/lib/utils";
import { Tree, TreeViewComponentProps, TreeViewElement } from "../../../../../../../src/components/ui/tree-view-api";
import { TreeMenuItem } from "./TreeMenuItem";
import { FacetValue } from "@coveo/headless";
import { IconChevronUp, IconChevronDown } from "@tabler/icons-react";

export type EventListener = { onClickElement: <T>(element: T) => void | Promise<void> }

export type TreeViewProps = {
    initialSelectedId?: string;
    elements: (TreeViewElement & { facet?: FacetValue, isSelected?: boolean })[];
} & (
        | {
            initialExpendedItems?: string[];
            expandAll?: false;
        }
        | {
            initialExpendedItems?: undefined;
            expandAll: true;
        }
    ) &
    TreeViewComponentProps;

export const TreeMenuView = ({
    elements,
    className,
    initialSelectedId,
    initialExpendedItems,
    onClickElement
}: TreeViewProps & EventListener) => {
    return (
        <div
            className={cn(
                "rounded-md relative",
                className,
            )}
        >
            <Tree
                initialSelectedId={initialSelectedId}
                initialExpendedItems={initialExpendedItems}
                elements={elements}
                className="w-full flex p-0"
                openIcon={<IconChevronUp className="text-slate-300 !p-0 !m-0 !w-5 !h-5 cursor-pointer" />}
                closeIcon={<IconChevronDown className="text-slate-300 !p-0 !m-0 !w-5 !h-5 cursor-pointer" />}
                indicator={false}
            >
                {
                    elements.map((element, index) => (
                        <TreeMenuItem
                            aria-label="Root"
                            key={element.id}
                            elements={[elements[index]]}
                            onClickElement={onClickElement}
                        />
                    ))
                }
            </Tree>
        </div>
    );
};

TreeMenuView.displayName = "TreeMenuView";