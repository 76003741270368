import {
  buildSearchEngine,
  SearchEngine,
  SearchEngineConfiguration,
  SearchEngineOptions,
} from "@coveo/headless";
import httpClient from "../../../api/httpClient";

interface CreateSearchEngineParams {
  configuration: SearchEngineConfiguration;
  searchHub: string;
  preprocessRequestBody?: (body: any) => any;
}

export const createSearchEngine = (params: CreateSearchEngineParams): SearchEngine => {
  const options: SearchEngineOptions = {
    configuration: {
      // re: analytics: { analyticsMode: "legacy" }
      // Only Coveo for Commerce supports EP at the moment.
      // For every other kind of implementation, set analyticsMode to legacy for the time being.
      // https://docs.coveo.com/en/headless/latest/usage/headless-usage-analytics/
      // https://docs.coveo.com/en/atomic/latest/usage/atomic-usage-analytics/
      analytics: { analyticsMode: "legacy" },
      renewAccessToken() {
        return httpClient({
          url: "/graphql",
          method: "post",
          data: JSON.stringify({
            query:
              "mutation($searchHub: String!) { coveoJwtCreate(input: { searchHub: $searchHub }) { jwt } }",
            variables: { searchHub: params.searchHub },
          }),
        }).then((response) => response.data.data.coveoJwtCreate.jwt);
      },
      ...params.configuration
    },
  };

  const preprocessRequestBody = params.preprocessRequestBody;

  if (preprocessRequestBody !== undefined) {
    options.configuration.preprocessRequest = (
      request,
      _clientOrigin,
      metadata,
    ) => {
      if (metadata?.method === "search" && request.body) {
        const body = JSON.parse(request.body.toString());

        request.body = JSON.stringify(preprocessRequestBody(body));
      }

      return request;
    };
  }

  return buildSearchEngine(options);
};
