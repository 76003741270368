import React from "react";
import { IconArrowRight, IconArrowLeft, IconDots } from "@tabler/icons-react";
import { ControllerType, useControllerContext } from "./EngineAndControllerProvider";
import { useSearchMade } from "../hooks/useSearchMade";
import { useControllerState } from "../../../../coveo/utils/useControllerState";
import { useNoResults } from "../hooks/noResults";
import { useWindowSize } from "../../../../../hooks/useWindowSize";

export const Pager: React.FC = () => {
  const controller = useControllerContext(ControllerType.Pager);
  
  const state = useControllerState(controller);

  const { width } = useWindowSize();

  const searchMade = useSearchMade();
  const noResults = useNoResults();

  const SmallScreenPaginationComponent = () => {
    return (
      <div className="flex flex-row border-solid border-l-0 border-r-0 border-t-2 border-b-2 border-slate-200 w-full py-6 px-0 mb-4 mt-12 justify-between">
        <button
          className='w-9 h-9 p-0 m-0 rounded-lg border border-solid border-slate-300 bg-white disabled:border-slate-50 hover:bg-slate-50 disabled:hover:bg-white flex justify-center items-center'
          disabled={!state.hasPreviousPage}
          onClick={controller.previousPage}>
          {
            <IconArrowLeft
              className="w-5 h-5 m-0 p-0 aria-disabled:text-slate-400"
              aria-disabled={!state.hasPreviousPage}
            />
          }
        </button>
        <div className="flex flex-col items-center justify-center">
          {
            'Page '
          }
          {
            controller.state.currentPage
          }
          {
            ' of '
          }
          {
            controller.state.maxPage
          }
        </div>
        <button
          className='w-9 h-9 p-0 m-0 rounded-lg border border-solid border-slate-300 bg-white disabled:border-slate-50 hover:bg-slate-50 flex justify-center items-center'
          disabled={!state.hasNextPage}
          onClick={controller.nextPage}>
          {
            <IconArrowRight
              className="w-5 h-5 m-0 p-0 aria-disabled:text-slate-400"
              aria-disabled={!state.hasNextPage}
            />
          }
        </button>
      </div>
    )
  }

  const BigScreenPaginationComponent = () => {
    return (
      <div className="flex flex-row border-solid border-l-0 border-r-0 border-t-2 border-b-2 border-slate-200 w-full py-6 px-0 mb-4 mt-12 justify-between align-middle">
        <button
          className='w-24 bg-white hover:bg-slate-50 p-0 m-0 border-none flex flex-row justify-center items-center'
          disabled={!state.hasPreviousPage}
          onClick={controller.previousPage}>
          {
            <>
              <IconArrowLeft className="w-4 mr-2" />
              Previous
            </>
          }
        </button>
        <div className="flex flex-row">
          {state.currentPages.map((page) => (
            <button
              className="w-10 h-10 m-0 bg-white disabled:bg-slate-50 hover:bg-slate-100 rounded-lg border-0 flex flex-row justify-center items-center"
              key={page}
              disabled={controller.isCurrentPage(page)}
              onClick={() => controller.selectPage(page)}
            >
              {page}
            </button>
          ))}
          {
            state.currentPages.length > 0 && !(state.currentPages[state.currentPages.length - 1] === state.maxPage || state.currentPages[state.currentPages.length - 1] === state.maxPage - 1) && (
              <>
              <IconDots size={16} className="mx-2 mb-1 self-end p-0 w4" />
              <button
              className="w-10 h-10 m-0 bg-white disabled:bg-slate-50 hover:bg-slate-100 rounded-lg border-0 flex flex-row justify-center items-center"
              key={state.maxPage}
              onClick={() => controller.selectPage(state.maxPage)}
            >
              {state.maxPage}
            </button>
              </>
            )
          }
        </div>
        <button
          className='w-24 bg-white hover:bg-slate-50 p-0 m-0 border-none flex flex-row justify-center items-center'
          disabled={!state.hasNextPage}
          onClick={controller.nextPage}>
          {
            <>
              Next
              <IconArrowRight className="w-4 ml-2" />
            </>
          }
        </button>
      </div>
    )
  }

  if (!searchMade || noResults) {
    return null;
  }

  if (width && width < 600) {
    return <SmallScreenPaginationComponent />
  }
  return <BigScreenPaginationComponent />
};

export default Pager;
