import React from "react";
import { PartDTO } from "../../../../../../api/Parts/dtos/PartDTO";
import { AddToCartButton } from "../AddToCartButton";

export type RowAddToCartProps = {
  fullPartsToCompare?: PartDTO[];
  isLoadingAddingPartToCart: { [key: string]: boolean };
  onAddPartToCart: (part: string) => void;
};

export const RowAddToCart: React.FC<RowAddToCartProps> = ({
  fullPartsToCompare,
  isLoadingAddingPartToCart,
  onAddPartToCart,
}) => {
  return (
    <tr id="row-add-to-cart">
      {fullPartsToCompare?.map((partResult: PartDTO) => {
        return (
          <td key={`add-to-cart-${partResult.id}`} className="p-md">
            <AddToCartButton
              part={partResult}
              isLoadingAddingPartToCart={isLoadingAddingPartToCart}
              onAddPartToCart={onAddPartToCart}
            />
          </td>
        );
      })}
    </tr>
  );
};
