import React from "react";
import Badge from "../../../../components/vention_ui/shared_components/Badge";
import Button from "../../../../components/vention_ui/shared_components/Button";
import classNames from "classnames";

interface Props {
  pill: string;
  title: string;
  description?: string;
  linkLabel?: string;
  linkPath?: string;
  align?: "left" | "center" | "right";
  subHeading?: boolean;
  linkIcon?: string;
  maxWidth?: string;
  className?: string;
  whiteButtonLabel?: string;
  whiteButtonPath?: string;
}

const SectionLead: React.FC<Props> = ({
  pill,
  title,
  description,
  linkPath,
  linkLabel,
  align,
  subHeading,
  linkIcon,
  maxWidth,
  className,
  whiteButtonLabel,
  whiteButtonPath,
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={classNames(
        "section__lead",
        `section__lead--${align}`,
        className,
        {
          "section__lead--subheading": subHeading,
        },
      )}
      style={{ maxWidth: maxWidth }}
    >
      <Badge size="lg" color="brand" label={pill} theme="dark" />
      <h1 className="section__lead-title">{title}</h1>
      {description && (
        <p className="section__lead-description">{description}</p>
      )}
      <div className="flex items-center flex-col justify-center gap-4 md:flex-row">
        {linkLabel && linkPath && (
          <Button
            heirarchy="primary"
            size="xl"
            href={linkPath}
          >
            {linkLabel}
          </Button>
        )}
        {whiteButtonLabel && whiteButtonPath && (
          <Button heirarchy="secondary-gray" size="xl" href={whiteButtonPath}>
            {whiteButtonLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SectionLead;
