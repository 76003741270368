import React, { useEffect } from "react";
import { FacetList } from "./FacetList";
import { ResultCount } from "./ResultCount";
import { ResultsSection } from "./ResultsSection";
import Pager from "./Pager";
import { Sort } from "./Sort";
import { useNoResults } from "../hooks/noResults";
import { NoResults } from "./NoResults";
import { PartCategoryByCode } from "./Types";
import { Banner } from "./Banner";
import {
  ControllerType,
  useControllerContext,
} from "./EngineAndControllerProvider";
import { cn } from "@/lib/utils";

interface MainSectionProps {
  partCategoryByCode: PartCategoryByCode;
}

export const MainSection: React.FC<MainSectionProps> = ({
  partCategoryByCode,
}) => {
  const noResults = useNoResults();
  const controller = useControllerContext(ControllerType.QuerySummary);

  useEffect(() => {
    // Scroll to top when loading is complete
    if (!controller.state.isLoading) {
      scrollTo(0, 0);
    }
  }, [controller.state.isLoading]);

  if (noResults) {
    return <NoResults />;
  }

  return (
    <div
      className={cn(
        "p-4xl pb-6halfxl max-lg:px-2xl transition-opacity ease-in duration-700 opactiy-100",
        { "opacity-0": controller.state.isLoading },
      )}
    >
      <div className="md:flex gap-[32px] max-w-[1400px] mx-auto ">
        <div className="md:w-[216px] max-lg:hidden">
          <FacetList partCategoryByCode={partCategoryByCode} />
        </div>
        <div className="flex-grow">
          <Banner partCategoryByCode={partCategoryByCode} />
          <div className="flex justify-between items-center">
            <div>
              <ResultCount />
            </div>
            <Sort />
          </div>
          <ResultsSection />
          <Pager />
        </div>
      </div>
    </div>
  );
};
