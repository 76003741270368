import React, { PropsWithChildren, useContext } from "react";
import I18n from "../../../../../helpers/I18n";
import {
  IconMapPin,
  IconMessages,
  IconPackage,
  IconX,
} from "@tabler/icons-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Sort } from "./Sort";
import { ViewResultsButton } from "./ViewResultsButton";
import { SearchAndFilterButton } from "./SearchAndFilterButton";
import { FacetList } from "./FacetList";
import { PartCategoryByCode, CurrencyRegion, CurrencySymbol } from "./Types";
import { useSearchMade } from "../hooks/useSearchMade";
import {
  ControllerType,
  useControllerContext,
} from "./EngineAndControllerProvider";
import { useControllerState } from "../../../../coveo/utils/useControllerState";
import { latestPartCategoryByCode } from "./CategoryFacet";
import { RegionContext } from "./RegionProvider";
import { useQuerySimpleGQL } from "../../../../../hooks/useSimpleGQL";
import { FeaturedBrandsService } from "../../../../../api/FeaturedBrands/FeaturedBrandsService";
import { FeaturedBrandsCollectionDTO } from "../../../../../api/FeaturedBrands/Types";

interface HeaderProps extends PropsWithChildren {
  partCategoryByCode: PartCategoryByCode;
}

export const Header: React.FC<HeaderProps> = ({
  partCategoryByCode,
  children,
}) => {
  const { result } = useQuerySimpleGQL<FeaturedBrandsCollectionDTO, []>(
    "featuredBrands",
    [],
    FeaturedBrandsService.queryFeaturedBrands,
  );

  const searchMade = useSearchMade();
  const region = useContext(RegionContext);

  const searchBoxController = useControllerContext(ControllerType.SearchBox);
  const categoryController = useControllerContext(ControllerType.CategoryFacet);
  const priceController = useControllerContext(ControllerType.PriceFilter);
  const brandController = useControllerContext(ControllerType.BrandFacet);

  const searchState = useControllerState(searchBoxController);
  const categoryState = useControllerState(categoryController);
  const priceState = useControllerState(priceController);
  const brandState = useControllerState(brandController);

  const selectBrand = (brand: string) => {
    const facet = brandState.values.find(
      (facet) => facet.value.toLocaleLowerCase() === brand.toLocaleLowerCase(),
    );
    if (facet) {
      brandController.toggleSelect(facet);
    } else {
      brandController.toggleSelect({
        value: brand,
        state: "selected",
        numberOfResults: 0,
      });
    }
  };

  const BadgeContainer = ({ children, onClick }) => (
    <button
      onClick={onClick}
      className="pl-[12px] pr-[6px] py-[4px] gap-[4px] flex group flex-row border-2 border-slate-200 border-solid bg-slate-50 rounded-full h-fit w-fit justify-center items-center"
    >
      {children}
    </button>
  );

  const TextSearchBadge = () => {
    if (!searchState.value) {
      return null;
    }
    return (
      <BadgeContainer
        onClick={() => {
          searchBoxController.clear();
          searchBoxController.submit();
        }}
      >
        <span className="text-sm-medium">{searchState.value}</span>
        <IconX className="cursor-pointer text-xs p-0 m-0 size-[12px] text-slate-400 group-hover:text-slate-700" />
      </BadgeContainer>
    );
  };

  const CategorySearchBadge = () => {
    const selectedCategoryCode = categoryState.values.find(
      (category) => category.state === "selected",
    );

    if (!selectedCategoryCode) {
      return null;
    }

    const selectedCategory = latestPartCategoryByCode(
      selectedCategoryCode.value,
      partCategoryByCode,
    );

    return (
      <BadgeContainer onClick={() => categoryController.deselectAll()}>
        <span className="text-sm-medium">{selectedCategory.name}</span>
        <IconX className="cursor-pointer text-xs p-0 m-0 size-[12px] text-slate-400 group-hover:text-slate-700" />
      </BadgeContainer>
    );
  };

  const PriceSearchBadge = () => {
    if (!priceState.range) {
      return null;
    }

    const minPrice = priceState.range.start;
    const maxPrice = priceState.range.end;

    const currencyCode = CurrencyRegion[region];
    const currencySymbol = CurrencySymbol[region];

    return (
      <BadgeContainer onClick={() => priceController.clear()}>
        <span className="text-sm-medium">{`Price: ${currencySymbol}${minPrice} ${currencyCode}  - ${currencySymbol}${maxPrice} ${currencyCode}`}</span>
        <IconX className="cursor-pointer text-xs p-0 m-0 size-[12px] text-slate-400 group-hover:text-slate-700" />
      </BadgeContainer>
    );
  };

  const BrandSearchBadge = () => {
    if (
      !brandState.values ||
      brandState.values.filter((facet) => facet.state === "selected").length ===
        0
    ) {
      return null;
    }

    return (
      <BadgeContainer onClick={() => brandController.deselectAll()}>
        <span className="text-sm-medium">{`Brands: ${brandState.values
          .filter((facet) => facet.state === "selected")
          .map((facet) => facet.value)
          .join(", ")}`}</span>
        <IconX className="cursor-pointer text-xs p-0 m-0 size-[12px] text-slate-400 group-hover:text-slate-700" />
      </BadgeContainer>
    );
  };

  const ClearButton = () => {
    const onClick = () => {
      if (searchBoxController.state.value.length > 0) {
        searchBoxController.updateText("");
        searchBoxController.submit();
      }
      if (categoryController.state.hasActiveValues) {
        categoryController.deselectAll();
      }
      if (priceController.state.range) {
        priceController.clear();
      }
      if (brandController.state.hasActiveValues) {
        brandController.deselectAll();
      }
    };
    return (
      <button className="bg-transparent border-none" onClick={onClick}>
        <span className="text-sm-semibold text-blue-500 m-0">Clear</span>
      </button>
    );
  };

  return (
    <header className="bg-gray-50 text-gray-900 px-[32px] py-[16px] max-lg:px-[16px] border-solid border-0 border-b border-gray-200">
      <div className="max-w-[1400px] mx-auto">
        <div className="flex justify-between h-8 mb-2">
          <span className="text-lg-semibold">
            {I18n.t("views.parts_library.header")}
          </span>
          <ul className="list-none h-full flex items-center divide-x divide-y-0 divide-solid divide-gray-200 [&_a]:flex [&_a]:items-center [&_a]:no-underline">
            <li>
              <a href="/contact" className="text-xs-medium">
                <IconMessages className="w-6 h-6 mr-1" />
                {I18n.t("views.parts_library.headerLinks.support")}
              </a>
            </li>
          </ul>
        </div>

        {children}
        {searchMade && (
          <div className="flex flex-row mt-3 gap-2">
            <TextSearchBadge />
            <CategorySearchBadge />
            <PriceSearchBadge />
            <BrandSearchBadge />
            <ClearButton />
          </div>
        )}
        {!searchMade &&
          (result?.data?.featuredBrands?.collection?.length ?? 0) > 0 && (
            <div className="flex items-center text-lg mt-4 max-lg:!hidden">
              <span className="m-0 text-sm-medium">
                {I18n.t("views.parts_library.featuredBrands.label")}
              </span>
              <ul className="list-none flex items-center space-x-2 mb-0 pl-2">
                {result?.data?.featuredBrands?.collection
                  ?.filter((item) => !!item.name)
                  .map((item) => (
                    <li key={item.name}>
                      <button
                        className="p-0 m-0 no-underline text-blue-700 hover:text-blue-800 bg-transparent border-none"
                        onClick={() => selectBrand(item.name)}
                      >
                        <span className="text-sm-semibold">{item.name}</span>
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        <div className="lg:hidden">
          <Dialog>
            <DialogTrigger asChild>
              <SearchAndFilterButton />
            </DialogTrigger>
            <DialogContent
              className="pb-[72px] grid"
              title="Sort & Filter"
              variant="full-screen"
            >
              <div className="overflow-auto">
                <DialogDescription className="sr-only">
                  Sort and filter the search results
                </DialogDescription>
                <Sort />

                <FacetList partCategoryByCode={partCategoryByCode} />
                <DialogFooter className="absolute bottom-0 left-0 right-0 p-4">
                  <DialogClose asChild>
                    <ViewResultsButton />
                  </DialogClose>
                </DialogFooter>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </header>
  );
};
