import React from 'react'
import LandingPageHero from '../../../../../components/cms/landing_pages/sections/LandingPageHero'
import LandingPageMultiTile from '../../../../../components/cms/landing_pages/sections/LandingPageMultiTile'
import LandingPageTable from '../../../../../components/cms/landing_pages/sections/LandingPageTable'
import LandingPageLRContent from '../../../../../components/cms/landing_pages/sections/LandingPageLRContent'
import LandingPageInfoGraphic from '../../../../../components/cms/landing_pages/sections/LandingPageInfoGraphic'
import LandingPageShowcase from '../../../../../components/cms/landing_pages/sections/LandingPageShowcase'
import LandingPageResource from '../../../../../components/cms/landing_pages/sections/LandingPageResource'
import LandingPageStandardStartBuilding from '../../../../../components/cms/landing_pages/sections/LandingPageStandardStartBuilding'
import I18n from '../../../../../helpers/I18n'
import { ResourceDocObject } from '../../../../../components/cms/api/Types'
import { IconBinaryTree2, IconTool, IconPackages} from '@tabler/icons-react'
import LandingPageLinkedMultiCard from '../../../../../components/cms/landing_pages/sections/LandingPageLinkedMultiCard'

interface Props {
  resourcesByUuid: Record<string, ResourceDocObject>
}

const Page: React.FC<Props> = ({ resourcesByUuid }) => {
  return (
    <div className='actuators'>
      <LandingPageHero
        type='hero'
        pageTypeFlag={I18n.t('views.landing_pages.hardware_landing_pages.actuators.hero.page_type_flag')}
        title={I18n.t('views.landing_pages.hardware_landing_pages.actuators.hero.title')}
        subtitle={I18n.t('views.landing_pages.hardware_landing_pages.actuators.hero.subtitle')}
        sectionCTA={{
          label: I18n.t('views.landing_pages.hardware_landing_pages.actuators.hero.section_cta'),
          color: 'secondary',
        }}
        imageLayout='full'
        style={{
          backgroundImage:
            'https://s3.amazonaws.com/ventioncms/vention_images/images/000/007/610/original/actuators-hero-v4-final.jpg?1679494769',
          textColor: 'dark',
        }}
        resourcesByUuid={{}}
      />
      <LandingPageShowcase
        type='multiTile'
        backgroundImg= ''
        tiles={[
          {
            icon:<IconBinaryTree2 stroke={2} />,
            title: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.multi_tile_1.diverse_range.title'
            ),
            description: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.multi_tile_1.diverse_range.description'
            ),
          },
          {
            icon: <IconTool stroke={2}/>,
            title: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.multi_tile_1.high_configurability.title'
            ),
            description: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.multi_tile_1.high_configurability.description'
            ),
          },
          {
            icon: <IconPackages stroke={2}/>,
            title: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.multi_tile_1.simple_deployment.title'
            ),
            description: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.multi_tile_1.simple_deployment.description'
            ),
          },
        ]}
        style={{
          paddingTop: '25',
          paddingBottom: '80',
        }}
      />
      <LandingPageTable
        type='table'
        data={[
          I18n.t('views.landing_pages.hardware_landing_pages.actuators.table.data_1').reduce(
            (obj, { key, value }) => {
              obj[key] = value
              return obj
            },
            {}
          ),
          I18n.t('views.landing_pages.hardware_landing_pages.actuators.table.data_2').reduce(
            (obj, { key, value }) => {
              obj[key] = value
              return obj
            },
            {}
          ),
          I18n.t('views.landing_pages.hardware_landing_pages.actuators.table.data_3').reduce(
            (obj, { key, value }) => {
              obj[key] = value
              return obj
            },
            {}
          ),
          I18n.t('views.landing_pages.hardware_landing_pages.actuators.table.data_4').reduce(
            (obj, { key, value }) => {
              obj[key] = value
              return obj
            },
            {}
          ),
          I18n.t('views.landing_pages.hardware_landing_pages.actuators.table.data_5').reduce(
            (obj, { key, value }) => {
              obj[key] = value
              return obj
            },
            {}
          ),
          I18n.t('views.landing_pages.hardware_landing_pages.actuators.table.data_6').reduce(
            (obj, { key, value }) => {
              obj[key] = value
              return obj
            },
            {}
          ),
        ]}
        tableStyle={{
          layout: 'vertical',
          borderColumn: false,
          alterningRowBackgroundColor: true
        }}
        subtitle ={I18n.t('views.landing_pages.hardware_landing_pages.actuators.table.subtitle')}
        tableHeader={I18n.t('views.landing_pages.hardware_landing_pages.actuators.table.header')}
        description={I18n.t('views.landing_pages.hardware_landing_pages.actuators.table.description')}
        titleTextAlign='center'
        style={{
          backgroundColor: 'white',
          paddingTop: '40',
        }}
      />
      <LandingPageLinkedMultiCard
        type='multiTile'
        title={I18n.t('views.landing_pages.hardware_landing_pages.actuators.multi_tile_2.title')}
        titleTextAlign='center'
        countTiles={3}
        tiles={[
          {
            imageUrl:
              'https://s3.amazonaws.com/ventioncms/vention_images/images/000/006/684/original/profile-railes-enclosed-ball-screw-optimized.png?1645036593',
            title: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.multi_tile_2.profile_rails.title'
            ),
            description: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.multi_tile_2.profile_rails.description'
            ),
            linkUrl:
              'https://vention.io/parts-library?category=MV&sub_category=PS#f-ec_category=MV%7CMV-PS',
            linkLabel: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.multi_tile_2.profile_rails.link_label'
            ),
          },
          {
            imageUrl:
              'https://s3.amazonaws.com/ventioncms/vention_images/images/000/002/843/original/linearbearingandrails.png?1580496057',
            title: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.multi_tile_2.linear_rails.title'
            ),
            description: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.multi_tile_2.linear_rails.description'
            ),
            linkUrl: 'https://vention.io/parts-library?category=MV&sub_category=LG#f-ec_category=MV%7CMV-LG',
            linkLabel: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.multi_tile_2.linear_rails.link_label'
            ),
          },
          {
            imageUrl:
              'https://s3.amazonaws.com/ventioncms/vention_images/images/000/002/844/original/rollerbearingsguidance.png?1580496082',
            title: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.multi_tile_2.eccentric_rollers.title'
            ),
            description: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.multi_tile_2.eccentric_rollers.description'
            ),
            linkUrl: 'https://vention.io/parts-library?category=MV&sub_category=RW#f-ec_category=MV%7CMV-RW',
            linkLabel: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.multi_tile_2.eccentric_rollers.link_label'
            ),
          },
        ]}
        style={{
          backgroundColor: 'vention-grey',
        }}
      />
      <LandingPageLRContent
        type='lrContent'
        textAlign='right'
        pageTypeFlag={I18n.t('views.landing_pages.hardware_landing_pages.actuators.lr_content_1.page_type_flag')}
        title={I18n.t('views.landing_pages.hardware_landing_pages.actuators.lr_content_1.title')}
        content={[
          {
            content: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.lr_content_1.content'
            ),
          },
        ]}
        imageUrl='https://s3.amazonaws.com/ventioncms/vention_images/images/000/003/361/original/rotary-actuator-v2.png?1586810959'
        cta={{
          url: 'https://vention.io/docs/datasheets/rotary-actuator-v2-datasheet-84',
          label: I18n.t(
            'views.landing_pages.hardware_landing_pages.actuators.lr_content_1.cta_label'
          ),
        }}
        style={{
          backgroundColor: 'white',
        }}
      />
      <LandingPageInfoGraphic
        type='infoGraphic'
        imageUrl='https://s3.amazonaws.com/ventioncms/vention_images/images/000/006/683/original/enclosed-ball-screw-actuator-infographic-optimized-resized-final-blue.jpg?1644986601'
        alt={I18n.t('views.landing_pages.hardware_landing_pages.actuators.info_graphic.image_alt')}
        title={I18n.t('views.landing_pages.hardware_landing_pages.actuators.info_graphic.title')}
        subtitle=''
        textAlign='center'
        cta={{
          label: I18n.t(
            'views.landing_pages.hardware_landing_pages.actuators.info_graphic.cta_label'
          ),
          url: 'https://vention.io/parts-library',
        }}
        description=''
        style={{
          backgroundColor: 'vention-blue',
        }}
      />
      <LandingPageLRContent
        type='lrContent'
        textAlign='left'
        title={I18n.t('views.landing_pages.hardware_landing_pages.actuators.lr_content_2.title')}
        content={[
          {
            content: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.lr_content_2.content'
            ),
          },
        ]}
        imageUrl='https://s3.amazonaws.com/ventioncms/vention_images/images/000/006/663/original/actuators-applications-optimized-v2.jpg?1644954844'
        cta={{
          url: 'https://vention.io/applications',
          label: I18n.t(
            'views.landing_pages.hardware_landing_pages.actuators.lr_content_2.cta_label'
          ),
        }}
        style={{
          backgroundColor: 'white',
        }}
      />
      <LandingPageResource
        type='resource'
        title={I18n.t('views.landing_pages.hardware_landing_pages.actuators.resource.title')}
        uuids={[
          'd9d8996d-fd2d-4029-9a26-2022d8b78039',
          'c3e5888a-1f81-40f7-b907-b4756766032a',
          '48d5d900-c561-48f7-a1e4-5650c4ad3f97',
        ]}
        resourcesByUuid={resourcesByUuid}
      />
      <LandingPageStandardStartBuilding
        type='standardStartBuilding'
        title={I18n.t('views.landing_pages.hardware_landing_pages.actuators.start_building.title')}
        description={I18n.t(
          'views.landing_pages.hardware_landing_pages.actuators.start_building.description'
        )}
        buttons={{
          getStarted: {
            label: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.start_building.get_started_label'
            ),
          },
          contactUs: {
            label: I18n.t(
              'views.landing_pages.hardware_landing_pages.actuators.start_building.contact_us_label'
            ),
          },
        }}
      />
    </div>
  )
}

export default Page
