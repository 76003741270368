import React from "react";
import { PartDTO } from "../../../../../../api/Parts/dtos/PartDTO";

export type RowSpecificationTitleProps = {
  fullPartsToCompare?: PartDTO[];
  title: string;
};

export const RowSpecificationTitle: React.FC<RowSpecificationTitleProps> = ({
  fullPartsToCompare,
  title,
}) => {
  return (
    <tr id="row-general-specification">
      {fullPartsToCompare?.map((partResult: PartDTO) => {
        return (
          <td
            key={`generatil-specification-${partResult.id}`}
            className="px-md pt-lg"
          >
            <div className="border border-gray-200 border-collapse border-solid bg-gray-light-50 flex justify-center items-center py-md">
              <span className="text-xs-bold">{title}</span>
            </div>
          </td>
        );
      })}
    </tr>
  );
};
