import classNames from "classnames";
import React from "react";

interface TableProps {
  hiddenHeaders?: string[];
  headers?: string[];
  data: ReadonlyArray<ReadonlyArray<React.ReactNode>>;
}

const Table: React.FC<TableProps> = ({ hiddenHeaders, headers, data }) => {
  return (
    <table className="vention-ui-table">
      <thead>
        <tr className={classNames("screen-reader-text")}>
          {hiddenHeaders?.map((header, index) => <th key={index}>{header}</th>)}
        </tr>
      </thead>
      <tbody>
        <tr>
          {headers?.map((header, index) => <td key={index}>{header}</td>)}
        </tr>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
