import React from "react";
import { PartDTO } from "../../../../../../api/Parts/dtos/PartDTO";

export type RowImageProps = {
  fullPartsToCompare?: PartDTO[];
  mainPart: Partial<PartDTO>;
};

export const RowImage: React.FC<RowImageProps> = ({
  fullPartsToCompare,
  mainPart,
}) => {
  return (
    <tr id="row-image">
      {fullPartsToCompare?.map((partResult: PartDTO) => {
        return (
          <td key={`image-${partResult.id}`} className="p-md">
            <div className="relative flex">
              <div className="w-[180px] h-[180px] self-center bg-slate-900/5 transition-colors duration-200 ease-in-out relative overflow-hidden">
                <img
                  className="mix-blend-multiply block w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  src={partResult.imagePath}
                  alt={partResult.name}
                  loading="lazy"
                />
              </div>
              {`${mainPart.id}` === partResult.id && (
                <p className="absolute top-0 left-1/2 transform -translate-x-1/2  border border-solid text-brand-700 bg-brand-50 border-brand-200 rounded-2xl px-lg py-xxs mt-md text-sm-medium">
                  Pinned
                </p>
              )}
            </div>
          </td>
        );
      })}
    </tr>
  );
};
