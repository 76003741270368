import React from "react";
import { ShowFastener, ShowPart } from "../Page";
import Table from "../../../../../components/vention_ui/shared_components/Table";

interface Props {
  readonly part: ShowPart;
}

interface FastenerProps {
  readonly childParts: ReadonlyArray<ShowFastener>;
}

type Specs = ReadonlyArray<{
  label: string;
  value: string | React.ReactNode;
  specName?: string;
}>;

const convertSpecsToTableData = (specs: Specs) => {
  return specs.map(({ label, value, specName }) => [
    label,
    formatSpecValue(formatSpecExponent(value), specName),
  ]);
};

const formatSpecValue = (
  value: string | React.ReactNode,
  specName?: string,
) => {
  if (specName === "inTheBox" && typeof value === "string") {
    return (
      <ul>
        {value.split(",").map((valueItem, index) => (
          <li key={index}>{valueItem}</li>
        ))}
      </ul>
    );
  }
  return value;
};

const formatSpecExponent = (value: string | React.ReactNode) => {
  if (typeof value === "string") {
    const splitValue = value.split("^");
    if (splitValue.length == 2) {
      return (
        <>
          {splitValue[0]}
          <sup>{splitValue[1]}</sup>
        </>
      );
    }
  }

  return value;
};

const Fasteners: React.FC<FastenerProps> = ({ childParts }) => {
  return (
    <>
      {childParts.map((childPart, index) => (
        <span key={index}>
          {`${childPart.child_repeat_count} x `}
          <a href={`/parts/${childPart.child_id}`}>
            {childPart.part.part_number}
          </a>
          {index + 1 !== childParts.length ? ", " : ""}
        </span>
      ))}
    </>
  );
};

const Specifications: React.FC<Props> = ({ part }) => {
  let generalSpecsMap: Specs = [
    {
      label: "Part Number",
      value: part.part_number,
    },
    {
      label: "Unit Price",
      value: part.hide_price ? "Contact us" : part.display_price,
    },
  ];

  if (!part.is_software) {
    generalSpecsMap = [
      ...generalSpecsMap,
      ...(part.weight && part.weight > 0
        ? [
            {
              label: "Weight",
              value: `${part.weight} kg`,
            },
          ]
        : []),
      ...([part.length, part.width, part.height].every(
        (number) => number && number > 0,
      )
        ? [
            {
              label: "Dimensions (L x W x H)",
              value: `${part.length} x ${part.width} x ${part.height} mm`,
            },
          ]
        : []),
      ...(part.material
        ? [
            {
              label: "Material",
              value: part.material,
            },
          ]
        : []),
      ...(part.surface_finish
        ? [
            {
              label: "Surface finish",
              value: part.surface_finish,
            },
          ]
        : []),
      ...(part.applicable_standards
        ? [
            {
              label: "Standards",
              value: part.applicable_standards,
            },
          ]
        : []),
      ...(part.associated_fasteners.length > 0
        ? [
            {
              label: "Associated Fasteners",
              value: <Fasteners childParts={part.associated_fasteners} />,
            },
          ]
        : []),
      ...(part.light_duty_fasteners.length > 0
        ? [
            {
              label: "Light-Duty Fasteners",
              value: <Fasteners childParts={part.light_duty_fasteners} />,
            },
          ]
        : []),
      ...(part.vention_comment
        ? [
            {
              label: "Vention comment",
              value: part.vention_comment,
            },
          ]
        : []),
    ];
  }

  const techSpecsMap = part.technical_specifications.map((specGroup) => {
    return {
      title: specGroup.label,
      specs: specGroup.properties.map((property) => {
        return {
          label: property.label,
          value: property.unit
            ? `${property.value} ${property.unit}`
            : property.value,
          specName: property.spec_name,
        };
      }),
    };
  });

  return (
    <section id="specifications" className="product-viewer-v2__specifications">
      <div className="product-viewer-v2__specifications-data">
        <Table
          hiddenHeaders={["Spec Attribute", "Spec Value"]}
          headers={["General Specifications", ""]}
          data={[...convertSpecsToTableData(generalSpecsMap)]}
        />
      </div>
      {techSpecsMap.length > 0 && (
        <>
          {techSpecsMap.map((techSpecsGroup, index) => (
            <div className="product-viewer-v2__specifications-data" key={index}>
              <Table
                hiddenHeaders={["Spec Attribute", "Spec Value"]}
                headers={[techSpecsGroup.title, ""]}
                data={[...convertSpecsToTableData(techSpecsGroup.specs)]}
              />
            </div>
          ))}
        </>
      )}
    </section>
  );
};

export default Specifications;
