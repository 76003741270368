import React, { Fragment, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogMain,
} from "@/components/ui/dialog";
import { ProjectService } from "../../../../../api/Projects/ProjectsService";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { ErrorIcon, SuccessIcon } from "@/components/ui/toaster";
import { useMutationGQL } from "../../../../../hooks/useMutationGQL";
import { Spinner } from "../../../../../components/erb_wrappers/dashboard/projects/components/Spinner";
import { IconFolderQuestion, IconPlus } from "@tabler/icons-react";
import { ShowPart } from "../Page";
import { useInfiniteScroll } from "../../../../../hooks/useInfiniteScroll";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  GetProjectsCollectionDTO,
  GetProjectsDTO,
} from "../../../../../api/Projects/queries/project.queries";
import { useProjectNavigation } from "../../../../../components/erb_wrappers/dashboard/projects/hooks/useProjectNavigation";
import { useUserContext } from "../../../../../contexts/UserContext";

export type AddPartToProjectModalProps = {
  part: ShowPart | null;
  onClose: () => void;
};

export const AddPartToProjectModal: React.FC<AddPartToProjectModalProps> = ({
  part,
  onClose,
}) => {
  const { toast } = useToast();
  const [selectedProject, setSelectedProject] = React.useState<
    GetProjectsDTO | undefined
  >();

  const { userId } = useUserContext();

  const navigation = useProjectNavigation();
  const [quantity, setQuantity] = React.useState(1);

  const infiniteQuery = useInfiniteQuery({
    queryKey: ["projects", "userProjects"],
    initialPageParam: 1,
    enabled: part !== null,
    queryFn: async ({ pageParam = 1 }) => {
      return ProjectService.queryProjects(
        pageParam,
        20,
        "createdAt",
        "desc",
        null,
        null,
        null,
        "Active",
        "Editor",
      );
    },
    getNextPageParam: (lastPage: GetProjectsCollectionDTO) => {
      const nextPageParam =
        lastPage?.projects?.metadata?.currentPage <
        lastPage?.projects?.metadata?.totalPages
          ? lastPage?.projects?.metadata?.currentPage + 1
          : undefined;
      return nextPageParam;
    },
  });

  const projectParts = useQuery({
    queryKey: ["projectParts", part?.id],
    queryFn: async () => {
      return ProjectService.queryPartsForProject(
        1,
        1000,
        selectedProject?.id ?? "",
      );
    },
    enabled: selectedProject !== undefined,
  });

  const {
    scrollRef,
    handleScrollBottom,
    data: unifiedProjects,
    handleClearData,
  } = useInfiniteScroll<GetProjectsCollectionDTO, "projects", GetProjectsDTO>(
    "projects",
    infiniteQuery,
    part !== null,
  );

  const mutationSaveProjectPart = useMutationGQL(
    "Save Project",
    ProjectService.mutateSaveProjectPart,
  );

  useEffect(() => {
    const hasOneElement = (unifiedProjects.collection?.length ?? 0) === 1;
    if (hasOneElement) {
      setSelectedProject(unifiedProjects.collection?.[0]);
    }
  }, [unifiedProjects]);

  const handleOnClose = () => {
    setSelectedProject(undefined);
    setQuantity(1);
    onClose();
  };

  const handleValueChange = (value: string) => {
    setSelectedProject(
      unifiedProjects?.collection?.find(
        (project) => project.id.toString() === value,
      ),
    );
  };

  const getCurrentPartQuantityInProject = () => {
    const existingQuantity =
      projectParts.data?.projectItems?.collection?.find(
        (projectPart) => projectPart.item.id.toString() === part?.id.toString(),
      )?.quantity ?? 0;
    return existingQuantity;
  };

  const handleSave = async () => {
    if (!selectedProject || !part) {
      return;
    }
    try {
      const existingQuantity = getCurrentPartQuantityInProject();
      const result = await mutationSaveProjectPart.mutationFn([
        selectedProject.id,
        part.id,
        quantity + existingQuantity,
      ]);

      const projectPartId = result.projectItemSave?.projectItem?.id;

      if (projectPartId) {
        toast({
          title: "Part successfully imported",
          icon: SuccessIcon,
        });
      } else {
        console.error("Failed to add existing part to project", result);
        toast({
          title: "There was an error adding the part. Please try again.",
          icon: ErrorIcon,
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "There was an error adding the part. Please try again.",
        icon: ErrorIcon,
      });
    }

    handleOnClose();
  };

  const handleOnCreateProject = () => {
    if (!userId) {
      window.location.href = "/users/sign_in?return_to=/dashboard/projects";
      return;
    }
    navigation.openToCreateANewProject();

    handleOnClose();
    handleClearData();
  };

  const isLoading = infiniteQuery.isFetching;
  const hasData = (unifiedProjects.collection?.length ?? 0) > 0;

  return (
    <Dialog open={part !== null} onOpenChange={(_) => handleOnClose()}>
      <DialogContent variant="modal" title="Add parts to project">
        <DialogMain description="Add part to project">
          {isLoading && !hasData && <Spinner />}
          {hasData && (
            <div className="flex flex-col gap-4">
              <span>Projects</span>
              <div
                className="flex flex-col gap-2  max-h-[360px] overflow-y-scroll"
                onScroll={handleScrollBottom}
                ref={scrollRef}
              >
                <RadioGroup
                  value={`${selectedProject?.id}`}
                  onValueChange={handleValueChange}
                >
                  {unifiedProjects.collection?.map((project) => (
                    <Fragment key={project.id}>
                      <div className="flex flex-row gap-2 py-xl px-3xl items-center">
                        <RadioGroupItem
                          value={`${project.id}`}
                        ></RadioGroupItem>
                        <div className="flex flex-col">
                          <span className="text-sm-medium">{project.name}</span>
                          <span className="text-sm-regular">
                            {project.owner?.displayName}
                          </span>
                        </div>
                      </div>
                      <div className="h-[1px] w-full bg-gray-200" />
                    </Fragment>
                  ))}
                  <Button
                    variant="secondaryColor"
                    className="border-none shadow-none w-fit py-xl"
                    onClick={handleOnCreateProject}
                  >
                    <IconPlus />
                    <span className="text-sm-semibold">Create a project</span>
                  </Button>
                </RadioGroup>
              </div>
              <span className="mt-4">Quantity</span>
              <div className="flex flex-row items-center border border-solid border-gray-200 rounded-md bg-gray-light-50">
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    if (quantity > 0) {
                      setQuantity(quantity - 1);
                    }
                  }}
                  variant="noButton"
                  className="border-r-[1px] border-gray-200 border-solid border-l-0 border-t-0 border-b-0 bg-white"
                >
                  -
                </Button>
                <span className="grow text-center ">{quantity}</span>
                <Button
                  variant="noButton"
                  className="border-l-[1px] border-gray-200 border-solid border-r-0 border-t-0 border-b-0 bg-white"
                  onClick={(e) => {
                    e.preventDefault();
                    setQuantity(quantity + 1);
                  }}
                >
                  +
                </Button>
              </div>
            </div>
          )}
          {!isLoading && !hasData && (
            <div className="flex flex-col items-center justify-center gap-3xl">
              <IconFolderQuestion
                className="border border-solid border-gray-200 rounded-lg p-lg"
                size={50}
              />
              <div className="items-center text-center justify-center flex flex-col">
                <span className="text-md-semibold">
                  You don’t have a project to add this part.
                </span>
                <span className="text-md-regular">
                  Projects help you organize specs, designs, parts, quotes, and
                  orders in one place.
                </span>
              </div>
              <Button
                variant="secondaryColor"
                className="border-none shadow-none"
                onClick={handleOnCreateProject}
              >
                <IconPlus />
                <span className="text-sm-semibold">Create a project</span>
              </Button>
            </div>
          )}
        </DialogMain>
        {hasData && (
          <DialogFooter>
            <Button
              onClick={handleOnClose}
              className="flex-1"
              variant="outline"
            >
              <span>Cancel</span>
            </Button>
            <Button
              disabled={
                selectedProject === undefined ||
                mutationSaveProjectPart.status === "pending" ||
                projectParts.isLoading
              }
              className="flex-1"
              onClick={handleSave}
            >
              {mutationSaveProjectPart.status === "pending" ||
                (projectParts.isLoading && <Spinner />)}
              <span>Add to project</span>
            </Button>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
};
