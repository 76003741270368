import React from "react";
import { PartDTO } from "../../../../../../api/Parts/dtos/PartDTO";
import { FormattedPrice } from "../FormattedPrice";

export type RowPriceProps = {
  fullPartsToCompare?: PartDTO[];
};

export const RowPrice: React.FC<RowPriceProps> = ({ fullPartsToCompare }) => {
  return (
    <tr id="row-price">
      {fullPartsToCompare?.map((partResult: PartDTO) => {
        return (
          <td key={`price-${partResult.id}`} className="p-md">
            <p className="text-display-xs-semibold mb-0 [&_sup]:text-md-regular [&_sup]:-top-[0.3rem] space-margin-top-sm space-margin-left-xxs h-[30px]">
              {partResult?.displayPrice && (
                <FormattedPrice
                  price={{
                    amount: partResult.displayPriceRaw.amount,
                    currency_code: partResult.displayPriceRaw.currencyCode,
                    currency_symbol: partResult.displayPriceRaw.currencySymbol,
                  }}
                />
              )}
            </p>
          </td>
        );
      })}
    </tr>
  );
};
