import React from "react";
import { ShowPart } from "../Page";
import I18n from "../../../../../helpers/I18n";

export const FormattedPrice: React.FC<{
  price: ShowPart["display_price_raw"];
}> = ({ price }) => {
  if (!price) {
    return null;
  }

  const [whole, decimal] = parseFloat(price.amount).toFixed(2).split(".");

  return (
    <>
      <sup>{price.currency_symbol}</sup>
      <span className="px-1">
        {parseInt(whole).toLocaleString(I18n.locale)}
      </span>
      <sup>
        {decimal !== "00" ? `.${decimal}` : ""} {`${price.currency_code}`}
      </sup>
    </>
  );
};
