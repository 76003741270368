import React from "react";
import CtaDivider from "../../../../components/common/dividers/CtaDivider";

interface Props {
  description: string;
  btnText: string;
  btnUrl: string;
}

const CtaDividerWrapper: React.FC<Props> = ({ description, btnText, btnUrl }) => {
  return (
    <CtaDivider description={description} 
    btnText={btnText}
    btnUrl={btnUrl}/>
  );
};

export default CtaDividerWrapper;
