import React, { useState } from "react";
import Button from "../../../../../components/vention_ui/shared_components/Button";
import QuantitySelector from "../../../../../components/vention_ui/shared_components/QuantitySelector";
import * as CartService from "../../../../../api/Cart/Service";
import { ShowPart } from "../Page";
import { FormattedPrice } from "./FormattedPrice";
import { SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS } from "../../constants";
import {
  IconFolder,
  IconHeadset,
  IconLayersIntersect,
} from "@tabler/icons-react";

interface Props {
  readonly product: ShowPart;
  readonly hasSimilarParts: boolean;
  readonly isProjectFeatureFlagEnabled: boolean;
  handleOnComparingParts: () => void;
  onAddToProjectClicked: () => void;
}

export const Cart: React.FC<Props> = ({
  product,
  isProjectFeatureFlagEnabled,
  hasSimilarParts,
  handleOnComparingParts,
  onAddToProjectClicked,
}) => {
  const [quantity, setQuantity] = useState<number | null>(null);
  const [addingToCart, setAddingToCart] = useState(false);

  const onQuantityChange = (quantity: number) => {
    setQuantity(quantity);
  };

  const onAddToCartClick = () => {
    if (!quantity) return;

    setAddingToCart(true);
    CartService.quickAddToCart(
      null,
      `/parts/${product.id}/buy?quantity=${quantity}`,
      "Part",
      () => setAddingToCart(false),
    );
  };

  const actions: {
    text: string;
    href: string | undefined;
    icon: JSX.Element;
    onClick?: () => void;
  }[] = [];
  if (isProjectFeatureFlagEnabled) {
    actions.push({
      text: "Add to project",
      href: undefined,
      icon: <IconFolder />,
      onClick: onAddToProjectClicked,
    });
  }

  actions.push({
    text: "Talk with an expert",
    href: "/contact/new?choice=technical+support",
    icon: <IconHeadset />,
  });

  if (hasSimilarParts) {
    // add the compare part action to the beginning of the list
    actions.unshift({
      text: "Compare part",
      href: "#part-recommendations",
      icon: <IconLayersIntersect />,
      onClick: handleOnComparingParts,
    });
  }

  return (
    <div className="product-viewer-v2__cart-card">
      {product.display_price_raw && (
        <p className="product-viewer-v2__header-price">
          <FormattedPrice price={product.display_price_raw} />
        </p>
      )}
      {product.display_price_raw &&
        product.shipment_delay_weeks <
          SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS && (
          <label
            className={`product-viewer-v2__availability product-viewer-v2__availability-${product.status_availability.className}`}
          >
            {product.status_availability.text}
          </label>
        )}

      {product.allow_checkout &&
      product.shipment_delay_weeks <
        SHIPMENT_DELAY_CONTACT_US_THRESHOLD_WEEKS ? (
        <>
          <div className="product-viewer-v2__header-selectors">
            <div className="product-viewer-v2__header-quantity">
              <QuantitySelector
                onQuantityChange={onQuantityChange}
                name="quantity"
                label="Quantity"
                size="xl"
              />
            </div>
          </div>
          <Button
            className="product-viewer-v2__header-cta"
            heirarchy="primary"
            size="xl"
            iconLeading="ti ti-shopping-cart"
            onClick={onAddToCartClick}
            disabled={addingToCart}
          >
            {addingToCart ? "Adding..." : "Add to cart"}
          </Button>
        </>
      ) : (
        <div className="flex flex-col max-w-80 w-full">
          <span className="text-sm-semibold text-secondary">
            Looking for more details?
          </span>
          <p className="text-sm-regular text-tertiary mb-0">
            This part requires custom specifications. Contact our sales team for
            tailored pricing and detailed information to ensure it fits your
            project perfectly.
          </p>
          <Button
            className="product-viewer-v2__header-cta"
            heirarchy="primary"
            size="xl"
            iconLeading="ti ti-messages"
            href="/contact"
          >
            Contact Us
          </Button>
        </div>
      )}

      <div className="flex flex-row mt-4 gap-1">
        <span className="text-sm-medium mb-0">Sold by</span>
        <span className="text-sm-regular mb-0">
          {product.sold_by || "Vention"}
        </span>
      </div>

      <div className="flex flex-row mt-1 gap-1">
        <span className="text-sm-medium mb-0">Ships from</span>
        <span className="text-sm-regular mb-0">
          {product.ship_from || "Vention"}
        </span>
      </div>

      <hr />
      <div className="flex flex-col gap-xl">
        {actions.map(({ text, href, icon, onClick }) => (
          <a
            key={text}
            href={href}
            onClick={onClick}
            className="no-underline text-sm-medium flex items-center gap-sm text-tertiary hover:text-tertiary-hover"
          >
            {icon} {text}
          </a>
        ))}
      </div>
    </div>
  );
};
