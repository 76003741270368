import React, { useEffect, useState } from "react";
import { AutoComplete } from "../../../../../partials/global/global-search/AutoComplete";
import { buildStandaloneSearchBox } from "@coveo/headless";
import { StandaloneSearchBox as StandaloneSearchBoxController} from "@coveo/headless";
import { createSearchEngine } from "../../../../coveo/utils/createEngine";

interface StandaloneSearchBoxProps {
  organizationId: string;
  accessToken: string;
}

const StandaloneSearchBox: React.FC<StandaloneSearchBoxProps> = ({ organizationId, accessToken }) => {
  const searchEngine = createSearchEngine(
    {
      configuration: {
        organizationId,
        accessToken,
        search: { pipeline: "Parts"},
      },
      searchHub: "PartLibrarySearch",
    }
  );

  const searchBoxController = buildStandaloneSearchBox(searchEngine, {options: {redirectionUrl: "/parts-library"}});

  return (
    <StandaloneSearchBoxInternal controller={searchBoxController}/>
  );
};

interface StandaloneSearchBoxInternalProps {
  controller: StandaloneSearchBoxController;
}

const StandaloneSearchBoxInternal: React.FC<StandaloneSearchBoxInternalProps> = ({ controller }) => {
  const [state, setState] = useState(controller.state);

  useEffect(
    () => controller.subscribe(() => {
      setState(controller.state);

      const { redirectTo, value, analytics } = controller.state;

      if (redirectTo) {
        const data = { value, analytics };

        localStorage.setItem("coveo_standalone_search_box_data", JSON.stringify(data));
  
        window.location.href = redirectTo;
      }
    }),
    [controller]
  );

  const onSearchSubmit = (value: string) => {
    controller.updateText(value);
    controller.submit();
  };

  return (
    <AutoComplete
      value={state.value}
      onChange={(value) => controller.updateText(value)}
      suggestions={state.suggestions.map((item) => item.rawValue)}
      onSubmit={onSearchSubmit}
      variant="full-width"
      placeholder="Search parts"
    />
  );
}

export default StandaloneSearchBox;
