import { buildUrlManager, SearchEngine, UrlManager } from "@coveo/headless";

export const initializeUrlManager = (engine: SearchEngine): () => void => {
  const fragment = window.location.hash.slice(1);

  const urlManager = buildUrlManager(engine, {
    initialState: { fragment: fragment },
  });

  urlManager.subscribe(() => {
    const hash = `#${urlManager.state.fragment}`;

    history.replaceState(null, document.title, hash);
  });

  return () => {
    urlManager.synchronize(fragment);
  };
};