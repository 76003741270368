import React from "react";
import {
  ControllerType,
  useControllerContext,
} from "./EngineAndControllerProvider";
import { useSearchMade } from "../hooks/useSearchMade";
import { useControllerState } from "../../../../coveo/utils/useControllerState";
import { useNoResults } from "../hooks/noResults";

export const ResultCount: React.FC = () => {
  const controller = useControllerContext(ControllerType.QuerySummary);
  const state = useControllerState(controller);

  const searchMade = useSearchMade();
  const noResults = useNoResults();

  if (!searchMade || noResults) {
    return null;
  }

  return (
    <>
      Results:
      <strong className="mx-1">
        {state.firstResult}-{state.lastResult}
      </strong>
      of
      <strong className="ml-1">{state.total}</strong>
    </>
  );
};
