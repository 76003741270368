import React, { useEffect, useState } from "react";
import {
  GeneralSpecification,
  PartDTO,
} from "../../../../../../api/Parts/dtos/PartDTO";
import { PartSkeletonCard } from "../../../../../../components/parts/PartSkeletonCard";
import { RowImage } from "./RowImage";
import { RowName } from "./RowName";
import { RowPrice } from "./RowPrice";
import { RowAddToCart } from "./RowAddToCart";
import { RowSpecificationTitle } from "./RowSpecificationTitle";
import { RowsGeneralSpecificationProperties } from "./RowsGeneralSpecificationProperties";
import { RowsSpecificationProperties } from "./RowsSpecificationProperties";
import { cn } from "@/lib/utils";

export type CompareModalProps = {
  mainPart: Partial<PartDTO>;
  selectedPartsToCompare: Partial<PartDTO>[];
  fullPartsToCompare?: PartDTO[];
  isOpen: boolean;
  allPropertyLabels?: Record<string, string[]>;
  isLoadingAddingPartToCart: { [key: string]: boolean };
  isLoading: boolean;
  onClose: () => void;
  onAddPartToCart: (part: string) => void;
};

export const CompareModal: React.FC<CompareModalProps> = (
  props: CompareModalProps,
) => {
  const {
    isOpen,
    selectedPartsToCompare,
    mainPart,
    fullPartsToCompare,
    allPropertyLabels,
    isLoadingAddingPartToCart,
    onClose,
    onAddPartToCart,
  } = props;

  const [oldStyle] = useState(document.body.style.overflow);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = oldStyle;
    }

    return () => {
      document.body.style.overflow = oldStyle;
    };
  }, [isOpen]);

  const handleOutClick = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    if (event?.target?.["className"] === "compare-part-modal") {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  if (props.isLoading) {
    return (
      <div className={ModalClass} onClick={handleOutClick}>
        <div className={ContainerClass}>
          <div className={HeaderClass}>
            <p className="text-sm-bold">
              {"Parts "}
              {`(${selectedPartsToCompare.length})`}
            </p>
            <button
              className="button-blank"
              style={{ position: "relative", right: "20px" }}
              onClick={onClose}
            >
              <span aria-hidden="true" className="icon-close"></span>
            </button>
          </div>
          <div className={cn(CompareContainerClass, "gap-xs")}>
            {Array(selectedPartsToCompare.length + 1)
              .fill(0)
              .map((_, index) => (
                <PartSkeletonCard key={index} />
              ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={ModalClass} onClick={handleOutClick}>
      <div className={ContainerClass}>
        <div className={HeaderClass}>
          <p className="text-xs-bold">
            {"Parts "}
            {`(${selectedPartsToCompare.length})`}
          </p>
          <button
            className="button-blank"
            style={{ position: "relative", right: "20px" }}
            onClick={onClose}
          >
            <span aria-hidden="true" className="icon-close"></span>
          </button>
        </div>
        <div className={CompareContainerClass}>
          <table>
            <colgroup>
              {fullPartsToCompare?.map((partResult) => {
                return (
                  <col
                    key={`col-${partResult.id}`}
                    aria-selected={`${mainPart.id}` === partResult.id}
                    className="aria-selected:bg-gray-light-200 w-[200px]"
                  />
                );
              })}
            </colgroup>
            <tbody className="pb-xl">
              <RowImage
                fullPartsToCompare={fullPartsToCompare}
                mainPart={mainPart}
              />

              <RowName fullPartsToCompare={fullPartsToCompare} />

              <RowPrice fullPartsToCompare={fullPartsToCompare} />

              <RowAddToCart
                isLoadingAddingPartToCart={isLoadingAddingPartToCart}
                onAddPartToCart={onAddPartToCart}
                fullPartsToCompare={fullPartsToCompare}
              />

              <RowSpecificationTitle
                fullPartsToCompare={fullPartsToCompare}
                title="General Specification"
              />

              <RowsGeneralSpecificationProperties
                fullPartsToCompare={fullPartsToCompare}
              />

              {Object.keys(allPropertyLabels ?? {}).map((keyLabel) => (
                <React.Fragment key={keyLabel}>
                  <RowSpecificationTitle
                    fullPartsToCompare={fullPartsToCompare}
                    title={keyLabel}
                  />
                  <RowsSpecificationProperties
                    key={keyLabel}
                    label={keyLabel}
                    fullPartsToCompare={fullPartsToCompare}
                    propertyLabels={allPropertyLabels?.[keyLabel] ?? []}
                  />
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const ModalClass =
  "fixed inset-0 z-[1000] w-full h-full bg-black/40 p-[5%] flex justify-center max-sm:p-0 max-xs:p-0";

const ContainerClass =
  "relative z-[1001] bg-white border border-gray-400 w-fit h-full rounded-lg overflow-auto max-sm:w-full max-xs:w-full";

const HeaderClass =
  "sticky top-0 flex justify-between bg-white z-[1002] w-full pt-xl px-2xl pb-xl max-sm:fixed max-xs:fixed";

const CompareContainerClass =
  "px-xl flex flex-row box-content border-none max-sm:pt-[60px] max-xs:pt-[60px]";
