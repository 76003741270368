import React from "react";
import { PartDTO } from "../../../../../../api/Parts/dtos/PartDTO";

export type RowNameProps = {
  fullPartsToCompare?: PartDTO[];
};

export const RowName: React.FC<RowNameProps> = ({ fullPartsToCompare }) => {
  return (
    <tr id="row-name">
      {fullPartsToCompare?.map((partResult: PartDTO) => {
        return (
          <td key={`name-${partResult.id}`} className="p-md">
            <p className="text-xs-semibold line-clamp-2 whitespace-pre-wrap">
              {partResult?.name}
            </p>
          </td>
        );
      })}
    </tr>
  );
};
