import { useState, useEffect } from "react";
import { EngineType, useEngineContext, ControllerType, useControllerContext } from "../components/EngineAndControllerProvider";

export const useSearchMade = () => {
  const searchEngine = useEngineContext(EngineType.Search);

  const categoryFacetController = useControllerContext(ControllerType.CategoryFacet);
  const priceFilterController = useControllerContext(ControllerType.PriceFilter);
  const sortController = useControllerContext(ControllerType.Sort);
  const brandFacetController = useControllerContext(ControllerType.BrandFacet);

  const [categoryFacetState, setCategoryFacetState] = useState(categoryFacetController.state);
  const [priceFilterState, setPriceFilterState] = useState(priceFilterController.state);
  const [sortState, setSortState] = useState(sortController.state);
  const [brandFacetState, setBrandFacetState] = useState(brandFacetController.state);

  useEffect(() => {
    const unsubscribeCategory = categoryFacetController.subscribe(() => setCategoryFacetState(categoryFacetController.state));
    const unsubscribePrice = priceFilterController.subscribe(() => setPriceFilterState(priceFilterController.state));
    const unsubscribeSort = sortController.subscribe(() => setSortState(sortController.state));
    const unsubscribeBrand = brandFacetController.subscribe(() => setBrandFacetState(brandFacetController.state));

    return () => {
      unsubscribeCategory();
      unsubscribePrice();
      unsubscribeSort();
      unsubscribeBrand();
    };
  }, [
    categoryFacetController,
    priceFilterController,
    sortController,
    brandFacetController
  ]);

  return (
    categoryFacetState.hasActiveValues ||
    priceFilterState.range ||
    (searchEngine.state.query && searchEngine.state.query.q !== "") ||
    sortState.sortCriteria !== "relevancy" ||
    brandFacetState.hasActiveValues
  );
};