import React from "react";
import I18n from "../../../../../../helpers/I18n";
import LandingPageHighlightedSteps from "../../../../../../components/cms/landing_pages/sections/LandingPageHighlightedSteps";

const HighlightedSteps = () => {
  return (
    <LandingPageHighlightedSteps
      title={I18n.t(
        "landing_pages.application_landing_pages.robotic_sanding.show.highlighted_steps.title",
      )}
      subtitle={I18n.t(
        "landing_pages.application_landing_pages.robotic_sanding.show.highlighted_steps.description",
      )}
      ctaBtn={{
        linkUrl: "https://try.vention.io/send-us-your-panels-let-us-show-you-the-finish",
        label: "Contact Us Today",
      }}
      steps={I18n.t(
        "landing_pages.application_landing_pages.robotic_sanding.show.highlighted_steps.steps",
      )}
    />
  );
};

export default HighlightedSteps;
