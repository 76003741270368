import React, { useState } from "react";
import { Facet as FacetController, FacetValue } from "@coveo/headless";
import { useControllerState } from "../../../../coveo/utils/useControllerState";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { useSearchMade } from "../hooks/useSearchMade";

export interface BrandFacetProps {
  controller: FacetController;
  title: string;
}

const MAX_BRANDS_BEFORE_COLLAPSE = 5;

export const BrandFacet: React.FC<BrandFacetProps> = (props) => {
  const { controller, title } = props;

  const state = useControllerState(controller);
  const searchMade = useSearchMade();

  const [isExpanded, setIsExpanded] = useState(false);

  const handleOnSelect = (element: FacetValue) => {
    controller.toggleSelect(element);
  };

  const searchItem = (searchTerm: string) => {
    controller.facetSearch.updateText(searchTerm);
    controller.facetSearch.search();
  };

  const elements = state.values.filter(
    (value) =>
      state.facetSearch.query.length === 0 ||
      state.facetSearch.values.findIndex(
        (element) => element.rawValue === value.value,
      ) > -1,
  );

  const collapsibleElements = isExpanded ? elements : elements.slice(0, 5);

  if (
    !searchMade &&
    !state.facetSearch.isLoading &&
    state.values.length === 0
  ) {
    return null;
  }

  return (
    <div className="min-w-56">
      <span className="text-md-medium text-primary flex flex-row bg-white justify-between w-full py-2 px-1 border-0 border-b border-secondary border-solid mt-2 mb-4">
        {title}
      </span>
      <div className="relative">
        {state.facetSearch.isLoading && (
          <div className="absolute right-4 top-4 animate-spin rounded-full h-5 w-5 border-slate-300 border-t border-l-0 border-r-2 border-b-3 border-dotted"></div>
        )}
        <Input
          type="text"
          onChange={(e) => searchItem(e.target.value)}
          placeholder="Search brand"
        />
      </div>
      <div className="flex flex-col">
        {!state.facetSearch.isLoading &&
          elements.length > 0 &&
          collapsibleElements.map((facetValue: FacetValue) => (
            <div
              key={facetValue.value}
              className="flex flex-row gap-[6px] my-[9px]"
            >
              <Checkbox
                id={facetValue.value}
                checked={facetValue.state === "selected"}
                className="size-[16px] self-center"
                onCheckedChange={() => handleOnSelect(facetValue)}
              />
              <button
                className="bg-transparent border-none p-0 m-0"
                onClick={() => handleOnSelect(facetValue)}
              >
                <span className="text-sm-regular">{facetValue.value} ({facetValue.numberOfResults})</span>
              </button>
            </div>
          ))}

        {!state.facetSearch.isLoading &&
          elements.length > MAX_BRANDS_BEFORE_COLLAPSE && (
            <button
              className="flex mt-[9px] mb-4 bg-transparent border-none p-0 text-brand-tertiary"
              onClick={() =>
                setIsExpanded((currentIsExpanded) => !currentIsExpanded)
              }
            >
              {isExpanded ? "Show less" : "Show more"}
            </button>
          )}

        {!state.facetSearch.isLoading && elements.length === 0 && (
          <div className="text-xs-medium">No results found</div>
        )}
      </div>
    </div>
  );
};
