import React from "react";
import { ResultCard, PartResult } from "./ResultCard";
import { Result } from "@coveo/headless";
import { cn } from "@/lib/utils";
import { ClassValue } from "clsx";

export interface PartsRowProps {
  results: Result[];
  title: string;
  wrapperClassName?: ClassValue;
}

export const PartsRow: React.FC<PartsRowProps> = (props: PartsRowProps) => {
  return (
    <div className={cn("mt-[32px] flex flex-col", props.wrapperClassName)}>
      <h2 className="text-md-semibold mb-[16px]">{props.title}</h2>
      <div className="grid gap-[8px] grid-flow-row grid-cols-2 2xl:grid-cols-7 xl:grid-cols-6 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-3">
        {props.results.map((result) => (
          <ResultCard key={result.uniqueId} data={result as PartResult} />
        ))}
      </div>
    </div>
  );
};
