import React from "react";
import BlogPostCard from "../../../../components/vention_ui/marketing_website_components/BlogPostCard";

interface Props {
  link: string;
  image: string;
  supertitle?: string;
  title: string;
  description?: string;
  author?: string;
  date?: string;
}

const BlogPostWrapper: React.FC<Props> = ({
  image,
  link,
  title,
  author,
  date,
  supertitle,
}) => {
  return (
    <>
      <BlogPostCard
        link={link}
        image={image}
        title={title}
        author={author}
        date={date}
        supertitle={supertitle}
      />
    </>
  );
};

export default BlogPostWrapper;
