import React from "react";
import {
  GeneralSpecification,
  PartDTO,
} from "../../../../../../api/Parts/dtos/PartDTO";
import { RowSpecificationCell } from "./RowSpecificationCell";

export type RowsGeneralSpecificationPropertiesProps = {
  fullPartsToCompare?: PartDTO[];
};

const GENERAL_SPECIFICATION_PROPERTY_NAME: (keyof GeneralSpecification)[] = [
  "partNumber",
  "weight",
  "width",
  "height",
  "displayPrice",
  "material",
] as const;

export const RowsGeneralSpecificationProperties: React.FC<
  RowsGeneralSpecificationPropertiesProps
> = ({ fullPartsToCompare }) => {
  return (
    <>
      {GENERAL_SPECIFICATION_PROPERTY_NAME.map((key, index) => {
        return (
          <tr key={`row-general-specification-${key}`}>
            {fullPartsToCompare?.map((partResult: PartDTO) => {
              let unit = "";
              if (key === "weight") {
                unit = "kg";
              } else if (key === "width" || key === "height") {
                unit = "mm";
              }

              const propertyValue = !partResult[key]
                ? "N/A"
                : `${partResult[key]} ${unit}`;
              return (
                <RowSpecificationCell
                  key={`general-specification-${key}-${partResult.id}`}
                  index={index}
                  propertyLabel={key}
                  propertyValue={propertyValue}
                />
              );
            })}
          </tr>
        );
      })}
    </>
  );
};
