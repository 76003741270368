import React from "react";
import { PartDTO } from "../../../../../../api/Parts/dtos/PartDTO";
import { TechnicalSpecificationPropertiesDTO } from "../../../../../../api/Parts/dtos/TechnicalSpecificationDTO";
import { RowSpecificationCell } from "./RowSpecificationCell";

export type RowsSpecificationPropertiesProps = {
  fullPartsToCompare?: PartDTO[];
  propertyLabels: string[];
  label: string;
};

export const RowsSpecificationProperties: React.FC<
  RowsSpecificationPropertiesProps
> = ({ propertyLabels, label, fullPartsToCompare }) => {
  const getProperty = (
    property: string,
    listOfProperties: TechnicalSpecificationPropertiesDTO[],
  ): TechnicalSpecificationPropertiesDTO => {
    return (
      listOfProperties.find((prop) => prop.label === property) ?? {
        label: property,
        spec_name: "",
        value: "",
        unit: "",
      }
    );
  };

  return (
    <>
      {propertyLabels.map((propertyKey, index) => {
        return (
          <tr key={`row-specification-${label}-${propertyKey}`}>
            {fullPartsToCompare?.map((partResult: PartDTO) => {
              const foundSpecificationOfPart =
                partResult.technicalSpecifications.find(
                  (technicalSpec) => technicalSpec.label === label,
                );
              const propertyToBeCompared = getProperty(
                propertyKey,
                foundSpecificationOfPart?.properties ?? [],
              );
              const propertyValue = !propertyToBeCompared.value
                ? "N/A"
                : `${propertyToBeCompared.value} ${propertyToBeCompared.unit ?? ""}`;

              return (
                <RowSpecificationCell
                  key={`specification-${label}-${propertyKey}-${partResult.id}`}
                  index={index}
                  propertyLabel={propertyToBeCompared.label}
                  propertyValue={propertyValue}
                />
              );
            })}
          </tr>
        );
      })}
    </>
  );
};
