import React, { PropsWithChildren } from "react";
import { createContext } from "react";
import { ContinentCode } from "../../../../../constants/Geography";

export const ContinentContext = createContext<ContinentCode | null>(null);

interface ContinentProviderProps extends PropsWithChildren {
  continentCode: ContinentCode | null;
}

export const ContinentProvider: React.FC<ContinentProviderProps> = ({ continentCode, children }) => {
  return <ContinentContext.Provider value={continentCode}>{children}</ContinentContext.Provider>;
}
