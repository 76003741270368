import { cn } from "@/lib/utils";
import React from "react";
import { splitAndCapitalize } from "../../../../../../helpers/StringHelper";
import WithTooltip from "../../../../../../components/common/WithTooltip";

export type RowSpecificationCellProps = {
  index: number;
  propertyValue: string;
  propertyLabel: string;
};

export const RowSpecificationCell: React.FC<RowSpecificationCellProps> = ({
  index,
  propertyLabel,
  propertyValue,
}) => {
  return (
    <td className="px-md">
      <div
        className={cn(
          "border border-gray-200 border-collapse border-solid p-lg",
          index % 2 === 1 ? "bg-gray-light-50" : "bg-white",
        )}
      >
        <span className="text-xs-medium">
          {splitAndCapitalize(propertyLabel)}
        </span>
        <WithTooltip tooltipClassName="compare-tooltip" label={propertyValue}>
          <span className="text-xs-regular line-clamp-2 whitespace-pre-wrap">
            {propertyValue}
          </span>
        </WithTooltip>
      </div>
    </td>
  );
};
