import { buildCriterionExpression } from "@coveo/headless";
import React from "react";
import {
  Select,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { SelectContent } from "@radix-ui/react-select";
import {
  ControllerType,
  useControllerContext,
} from "./EngineAndControllerProvider";
import { useSearchMade } from "../hooks/useSearchMade";
import { useControllerState } from "../../../../coveo/utils/useControllerState";
import { useNoResults } from "../hooks/noResults";

export const Sort: React.FC = () => {
  const controller = useControllerContext(ControllerType.Sort);
  const criteria = useControllerContext(ControllerType.Criteria);
  const state = useControllerState(controller);

  const searchMade = useSearchMade();
  const noResults = useNoResults();

  const getCriterionFromName = (name: string) =>
    criteria.find(([criterionName]) => criterionName === name)!;

  const getCurrentCriterion = () =>
    criteria.find(
      ([, criterion]) =>
        state.sortCriteria === buildCriterionExpression(criterion),
    )!;

  if (!searchMade || noResults) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 md:flex-row md:items-center md:my-4 text-md max-lg:!hidden">
      <label className="text-sm-bold m-0">Sort by:</label>
      <Select
        onValueChange={(value: string) => {
          controller.sortBy(getCriterionFromName(value)[1]);
        }}
      >
        <SelectTrigger className="w-48">
          <SelectValue placeholder={getCurrentCriterion()[0]} />
        </SelectTrigger>
        <SelectContent
          position="popper"
          className="w-48 z-50 border-2 border-slate-50 border-solid bg-white rounded-lg"
          align="end"
        >
          <SelectGroup>
            {criteria.map(([criterionName]) => (
              <SelectItem
                className="text-md hover:bg-slate-100"
                key={criterionName}
                value={criterionName}
              >
                {criterionName}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};
