import React from "react";
import { PartCategoryByCode, RegionType } from "./components/Types";
import { Header } from "./components/Header";
import SearchBox from "./components/SearchBox";
import { EngineAndControllerProvider } from "./components/EngineAndControllerProvider";
import { RegionProvider } from "./components/RegionProvider";
import { MainSection } from "./components/MainSection";
import { PageLayout } from "../../../../components/erb_wrappers/layout/PageLayout";
import { ContinentProvider } from "./components/ContinentProvider";
import { ContinentCode } from "../../../../constants/Geography";
import { UserProvider } from "../../../../contexts/UserContext";

interface PageProps {
  accessToken: string;
  organizationId: string;
  region: RegionType;
  shipmentSiteKey: number;
  partCategoryByCode: PartCategoryByCode;
  continentCode: ContinentCode | null;
}

const Page: React.FC<PageProps> = ({
  accessToken,
  organizationId,
  region,
  shipmentSiteKey,
  partCategoryByCode,
  continentCode
}) => (
  <PageLayout>
    <UserProvider>
      <ContinentProvider continentCode={continentCode}>
        <RegionProvider region={region}>
          <EngineAndControllerProvider
            accessToken={accessToken}
            organizationId={organizationId}
            shipmentSiteKey={shipmentSiteKey}
          >
            <Header partCategoryByCode={partCategoryByCode}>
              <SearchBox />
            </Header>
            <MainSection partCategoryByCode={partCategoryByCode} />
          </EngineAndControllerProvider>
        </RegionProvider>
      </ContinentProvider>
    </UserProvider>
  </PageLayout>
);

export default Page;
