import React, { useState } from "react";
import HeaderCard from "./HeaderCard";
import SectionLead from "../../../../shared_components/SectionLead";
import I18n from "../../../../../../../helpers/I18n";

interface Props {
  contentfulEntry: any;
}

const Header: React.FC<Props> = ({ contentfulEntry }) => {
  const [backgroundImageIndex, setBackgroundImageIndex] = useState<number>(0);
  const whiteButtonLabel = I18n.t(
    "landing_pages.application_landing_pages.master_palletizer.hero.whiteButtonLabel",
  );
  const whiteButtonPath = "https://try.vention.io/robot-palletizer-guide";
  return (
    <header className="master-palletizer-landing-page__header">
      <SectionLead
        align="center"
        maxWidth="800px"
        whiteButtonLabel={whiteButtonLabel}
        whiteButtonPath={whiteButtonPath}
        linkIcon="ti ti-arrow-right"
        {...contentfulEntry.headerSectionLead}
      />
      <div
        className="master-palletizer-landing-page__header-background-container"
        style={{
          backgroundImage: `url(${contentfulEntry.headerBackgroundImages[backgroundImageIndex].file.url})`,
        }}
      >
        <div className="master-palletizer-landing-page__header-background-gradient"></div>
      </div>
      <div className="master-palletizer-landing-page__header-cards container-lg">
        {contentfulEntry.headerFeatureCards.map((headerCardProps, index) => (
          <HeaderCard
            key={index}
            index={index + 1}
            setBackgroundImageIndex={setBackgroundImageIndex}
            {...headerCardProps}
          />
        ))}
      </div>
    </header>
  );
};

export default Header;
