import React from "react";
import I18n from "../../../../../helpers/I18n";
import LandingPageEducationalResources from "../../../../../components/cms/landing_pages/sections/LandingPageEducationalResources"

const FeatureCardLinks = () => {
  return (
    <LandingPageEducationalResources
      title={I18n.t(
        "landing_pages.marketing_landing_pages.marketplace_programs.show.featured_cards.heading",
      )}
      subtitle={I18n.t(
        "landing_pages.marketing_landing_pages.marketplace_programs.show.featured_cards.subheading",
      )}
      description={I18n.t(
        "landing_pages.marketing_landing_pages.marketplace_programs.show.featured_cards.description",
      )}
      cardDetails={I18n.t(
        "landing_pages.marketing_landing_pages.marketplace_programs.show.featured_cards.card_details",
      )}
    />
  );
};

export default FeatureCardLinks;
