import React from "react";
import I18n from "../../../../../../helpers/I18n";
import LandingPageMultiTile from "../../../../../../components/cms/landing_pages/sections/LandingPageMultiTile";

const Features = () => {
  return (
      <LandingPageMultiTile
        type="multiTile"
        imageMaxWidth={150}
        titleTextAlign="center"
        countTiles={4}
        textAlign="center"
        supertitle={I18n.t(
          "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.supertitle",
        )}
        title={I18n.t(
          "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.heading",
        )}
        subtitle={I18n.t(
          "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.subtitle",
        )}
        ctaBtn={{
          linkUrl: "https://try.vention.io/download-the-rapid-series-sanding-brochure",
          label: I18n.t(
            "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.ctaText",
          ),
        }}
        tiles={[
          {
            imageUrl:
              "https://assets.vention.io/marketing/application-landing-page/robotic-sanding/icons/Frame+48255.png",
            title: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile1.title",
            ),
            description: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile1.description",
            ),
          },
          {
            imageUrl:
              "https://assets.vention.io/marketing/application-landing-page/robotic-sanding/icons/Frame+48256.png",
            title: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile2.title",
            ),
            description: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile2.description",
            ),
          },
          {
            imageUrl:
              "https://assets.vention.io/marketing/application-landing-page/robotic-sanding/icons/Frame+48259.png",
            title: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile3.title",
            ),
            description: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile3.description",
            ),
          },
          {
            imageUrl:
              "https://assets.vention.io/marketing/application-landing-page/robotic-sanding/icons/Frame+48262.png",
            title: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile4.title",
            ),
            description: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile4.description",
            ),
          },
          {
            imageUrl:
              "https://assets.vention.io/marketing/application-landing-page/robotic-sanding/icons/Frame+48258.png",
            title: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile5.title",
            ),
            description: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile5.description",
            ),
          },
          {
            imageUrl:
              "https://assets.vention.io/marketing/application-landing-page/robotic-sanding/icons/Frame+48257.png",
            title: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile6.title",
            ),
            description: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile6.description",
            ),
          },
          {
            imageUrl:
              "https://assets.vention.io/marketing/application-landing-page/robotic-sanding/icons/Frame+48260.png",
            title: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile7.title",
            ),
            description: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile7.description",
            ),
          },
          {
            imageUrl:
              "https://assets.vention.io/marketing/application-landing-page/robotic-sanding/icons/Frame+48261.png",
            title: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile8.title",
            ),
            description: I18n.t(
              "landing_pages.application_landing_pages.robotic_sanding.show.multi_tile_1.tile8.description",
            ),
          }
        ]}
      />
  );
};

export default Features;
