import React from "react";
import { createContext } from "react";
import { RegionType } from "./Types";

export const RegionContext = createContext<RegionType>(RegionType.US);

interface RegionProviderProps {
  region: RegionType;
  children: any;
}

export const RegionProvider: React.FC<RegionProviderProps> = ({ region, children }) => {
  return <RegionContext.Provider value={region}>{children}</RegionContext.Provider>;
}
