import React from "react";
import {
  ControllerType,
  useControllerContext,
} from "./EngineAndControllerProvider";
import { Button } from "@/components/ui/button";

export const NoResults: React.FC = () => {
  const searchBoxController = useControllerContext(ControllerType.SearchBox);

  const submitEmptySearch = () => {
    searchBoxController.clear();
    searchBoxController.submit();
  };

  const backgroundImgageUrl =
    "https://assets.vention.io/page-assets/part-library/no_results_background.svg";

  return (
    <div className="flex flex-wrap justify-center">
      <div className="h-[480px] max-w-[480px] flex-1 flex flex-col">
        <div
          className="h-[300px] w-full flex flex-col justify-end items-center bg-no-repeat bg-bottom "
          style={{ backgroundImage: `url(${backgroundImgageUrl})` }}
        >
          <img
            src="https://assets.vention.io/page-assets/part-library/no_results_illustration.svg"
            alt="No results found"
          />
          <span className="mt-4 mb-1 text-md-semibold">
            No results found
          </span>
          <span className="text-sm-regular text-tertiary mb-0">
            Try refining your search terms or filters.
          </span>
        </div>
        <Button
          className="self-center mt-6"
          size="lg"
          variant="secondaryGray"
          onClick={submitEmptySearch}
        >
          <span className="text-md-semibold">Clear Search</span>
        </Button>
      </div>
    </div>
  );
};
