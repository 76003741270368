import React from "react";

export interface ContactCtaProps {
  hasResults: boolean;
}

const ContactCta: React.FC<ContactCtaProps> = ({
  hasResults,
}: ContactCtaProps) => {
  return (
    <p className="product-viewer-v2__contact-cta">
      {!hasResults && (
        <span className="mr-2">
          No technical documents available for this part.
        </span>
      )}
      Can’t find what you’re looking for? <a href="/contact">Contact us.</a>
    </p>
  );
};

export default ContactCta;
